.accordion-container {
    display: flex;
    height: 500px; /* Adjust height as needed */
    width: 100%; /* Ensure full width */
  }
  
  .accordion-item {
    flex: 1;
    transition: flex 0.3s ease;
    cursor: pointer;
    position: relative;
    display: flex;
    justify-content: center;
    background-color: #00454a;
    color: white;
    overflow: hidden;
    margin-right: 2px; /* Add space between tiles */
  }
  
  .accordion-title {
    font-size: 24px;
    font-weight: bold;
    /* position: absolute; */
    writing-mode: vertical-rl;
    text-align: center;
    bottom: 10px; /* Align the title at the bottom */
    transition: all 0.3s ease; /* Smooth transition when expanding */
    transform: rotate(180deg);
  }
  
  .accordion-content {
    display: none;
    font-size: 16px;
    padding: 20px;
    text-align: left;
    line-height: 1.5;
    flex:1;
    justify-content: space-between;
  }
  
  .accordion-item.active .accordion-content {
    display: block;
  }
  
  .accordion-item.active .accordion-title {
    transform: translateY(100%); /* Move title down when expanded */
    visibility: hidden; /* Hide title when expanded */
  }
  
  .accordion-item.active {
    flex: 4;
    background-color: #006d73;
  }
  
  .accordion-item:not(.active) {
    flex: 0.5;
    min-width: 80px;
  }
  
  .accordion-text {
    letter-spacing: 5px; /* Add space between letters */
  }
  
  .hero-image {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
    width: 60%;
  }
  
  .hero-content {
    width: 40%;
    padding-right: 20px;
  }
  .accordion-icon img {
    filter: brightness(0) invert(1);
    margin-bottom: 5vw;
    margin-top: 5vw;
  }
  
  .content-divider {
    border: 0;
    border-top: 1px solid white; /* White line after the content */
    margin: 10px 0; /* Adjust margin as needed */
  }
  .title-icon-container {
    display: flex;
    justify-content: space-between; /* Space between title and icon */
    align-items: center; /* Vertically center align the items */
  }

