/* Global Reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Arial', sans-serif;
  background-color: #ffffff;
  color: black;
}

/* Header Styling */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 100px;
  background-color: #ffffff;
}

.header .logo img {
  height: 50px;
}

/* Navigation Styling */
.navigation {
  display: flex;
  align-items: center;
}

.navigation a {
  position: relative;
  margin-left: 40px;
  text-decoration: none;
  color: black;
  font-weight: bold;
  font-size: 18px;
  transition: color 0.3s ease;
}

.navigation a::after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  bottom: -5px;
  width: 0;
  height: 2px;
  background-color: #198298;
  transition: width 0.3s ease;
}

.navigation a:hover::after {
  width: 100%;
}
.navigation a.home {
  border-right: 2px solid black; /* Right border */
  padding-right: 20px; /* Space between text and border */
}
.navigation a.contact {
  border: 2px solid black; /* Right border */
  padding: 6px; /* Space between text and border */
  border-radius: 20px;
  padding-left: 12px;
  padding-right: 12px;
  margin-left: 20px;
}




/* Hero Section Styling */
.hero {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding: 40px 80px; */
  padding-top: 15px;
  padding-bottom: 30px;
  background-color: #ffffff;
  min-height: 80vh;
  padding:10px;
}
.hero-1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding: 40px 80px; */
  background-color: #ffffff;

  padding:10px;
}

.hero-image {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hero-image img {
  max-width: 100%;
  height: auto;
  border-radius: 0px;
}

.hero-content {
  flex: 1;
  padding-left: 10vw;
}

.hero-content-1 {
  flex: 1;
  padding-left: 10vw;
  text-align: center;
}

.hero-content h1 {
  font-size: 40px;
  line-height: 1.2;
  margin-bottom: 2.5vw;
  color: black;
}
.hero-content h2 {
  font-size: 56px;
  line-height: 1.2;
  margin-bottom: 2.5vw;
  color: black;
}
.hero-content-1 h1 {
  font-size: 40px;
  line-height: 1.2;
  margin-bottom: 2.5vwpx;
  color: black;
}

.hero-content .highlight {
  color: #198298;
}

.hero-content p {
  font-size: 24px;

  line-height: 1.6;
  margin-bottom: 5vw;
  color: black;
}
.hero-content p1 {
  font-size: 20px;

  line-height: 1.6;
  margin-bottom: 5vw;
  color: black;
}

.hero-content-1 p {
  font-size: 24px;
  
  line-height: 1.6;
  margin-bottom: 5vw;
  color: black;
}


.cta-button {
  padding: 15px 30px;
  background-color: #198298;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 18px;
  cursor: pointer;
}



.cta-button:hover {
  background-color: #087f7b;
}

/* About Section */
.about {
  padding: 100px;
  background-color: #F5F6F8;
  text-align: center;
}

.about h2 {
  font-size: 36px;
  margin-bottom: 20px;
  color: #198298;
}

.about p {
  font-size: 18px;
  line-height: 1.6;
  max-width: 800px;
  margin: 0 auto 30px;
  color: #555;
}

.explore-button {
  padding: 15px 30px;
  background-color: #198298;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
}

.explore-button:hover {
  background-color: #087f7b;
}

/* Approach Section */
.approach {
  padding: 100px;
  background-color: #ffffff;
  text-align: center;
}

.approach h2 {
  font-size: 36px;
  margin-bottom: 40px;
  color: #198298;
}

.approach-content {
  display: flex;
  justify-content: center;
}

.approach-box {
  width: 50%;
  background-color: #F5F6F8;
  padding: 50px;
  border-radius: 10px;
}

.approach-box p {
  font-size: 18px;
  line-height: 1.6;
  color: #555;
}

.approach-box select {
  margin-top: 20px;
  padding: 10px;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

/* Solutions Section */
.solutions {
  padding: 100px;
  background-color: #F5F6F8;
  display: flex;
  flex-direction: column;
  gap: 50px;
}

.solution {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.solution h3 {
  font-size: 24px;
  margin-bottom: 20px;
  color: #198298;
}

.solution p {
  font-size: 18px;
  line-height: 1.6;
  margin-bottom: 20px;
  color: #555;
}

.read-more-button {
  display: block;
  padding: 15px 30px;
  background-color: white;
  color: #198298;
margin-top: 10%;
  border-radius: 30px;
  font-size: 16px;
border-width: '2px';
border-color: #198298;
  cursor: pointer;
}

.read-more-button:hover {
  background-color: #087f7b;
  color: white;
}

/* Footer Section */



.footer {
  position: relative;
  height: 700px;
  width: 100%;
  background-image: url('./assets/footer.png');
  background-size: cover;
  background-position: center bottom;
  background-repeat: no-repeat;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 50px;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 15%;
  padding-left: 50px;
  padding-right: 50px;
}

.footer-left {
  display: flex;
  flex-direction: column;
  max-width: 30%;
}

.footer-text h1 {
  font-size: 48px;
  margin: 0 0 20px 0;
  line-height: 1.5;
}

.footer-text p {
  font-size: 18px;
  line-height: 1.6;
  margin: 0;
}

.footer-nav {
  display: flex;
  gap: 20px;
  margin-top: 20px;
  font-size: 18px;
}

.footer-nav a {
  text-decoration: none;
  color: white;
  transition: color 0.3s ease;
}

.footer-nav a:hover {
  color: #A8E4EC; /* Lighter shade of the footer background for hover effect */
}

.footer-right {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  justify-content: space-between;
}

.contact-button {
  padding: 12px 24px;
  background-color: transparent;
  border: 2px solid white;
  border-radius: 30px;
  color: white;
  text-decoration: none;
  font-size: 18px;
  font-weight: bold;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.contact-button:hover {
  background-color: white;
  color: #198298;
}

.footer-social {
  display: flex;
  gap: 10px;
}

.footer-social .social-icon img {
  width: 40px;
  height: 40px;
}
.social-icons {
  display: flex;
  gap: 15px;
  align-self: flex-end;
}

.social-icons i {
  font-size: 20px;
  color: #007bff;
  background-color: white;
  border-radius: 50%;
  padding: 15px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  cursor: pointer;
  transition: transform 0.3s, color 0.3s;
}

.social-icons i:hover {
  transform: scale(1.1);
  color: #0056b3;
}



/* Responsive Design */
@media (max-width: 768px) {
  .header {
    padding: 20px;
    flex-direction: column;
    align-items: flex-start;
  }

  .header .navigation {
    margin-top: 10px;
  }

  .header .navigation a {
    margin-left: 0;
    margin-right: 20px;
  }

  .hero {
    flex-direction: column;
    padding: 50px 20px;
  }

  .hero-content {
    padding-left: 0;
    max-width: 100%;
    text-align: center;
  }

  .hero-content h1 {
    font-size: 36px;
  }

  

  .about,
  .approach,
  .solutions {
    padding: 50px 20px;
  }

  .approach-content {
    flex-direction: column;
  }

  .approach-box {
    width: 100%;
  }

  
  
}

@media (max-width: 767px) {
  .hero-image {
    width: 100%!important;
  }

  .hero-content {
    width: 100%!important;
  }

  .hero-content-1 {
    padding-left: 0vw!important;
  }

  .read-more-button {
    margin-bottom: 10%!important;
  }
}
@media (max-width: 576px) {
  .header {
    padding: 20px;
    flex-direction: column;
    align-items: flex-start;
  }

  .header .navigation {
    margin-top: 10px;
    /* flex-direction: column; */
  }

  .header .navigation a {
    margin: 10px;
    font-size: 14px;
  }

  .hero {
    flex-direction: column;
    padding: 30px 10px;
  }

  .hero-content {
    padding-left: 0;
    max-width: 100%;
    text-align: center;
  }

  .hero-content h1 {
    font-size: 28px;
  }

  .hero-content h2 {
    font-size: 36px;
  }

  .about,
  .approach,
  .solutions {
    padding: 40px 10px;
  }

  .approach-content {
    flex-direction: column;
  }

  .approach-box {
    width: 100%;
  }

  .footer-content {
    margin-top: 20px;
    text-align: center;
  }

  .footer-text h1 {
    font-size: 24px;
  }

  .footer-text p {
    font-size: 14px;
  }

  .footer-nav {
    flex-direction: column;
    gap: 10px;
    margin-top: 20px;
  }

  .social-icons {
    flex-direction: column;
    gap: 10px;
    margin-top: 20px;
  }
}
